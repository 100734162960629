.price, .price-section, .side-cart, .card-section--price {
  display: none;
}

.header {
  padding-bottom: 0;
}
.header .header__inner {
  display: grid;
  grid-template-areas: "mobileMenu logo logo" "quickSearchBlock quickSearchBlock nav";
  grid-template-columns: calc(50% - 100px) 1fr;
  align-items: center;
}
.header .header__inner .sku-add {
  display: none;
}
.header .header__inner .mobileMenu-toggle {
  grid-area: mobileMenu;
  position: relative;
  margin-left: -18px;
}
.header .header__inner .mobileMenu-toggle .mobileMenu-toggleIcon, .header .header__inner .mobileMenu-toggle .mobileMenu-toggleIcon::before, .header .header__inner .mobileMenu-toggle .mobileMenu-toggleIcon::after {
  background: #414141;
}
.header .header__inner .search-utils {
  max-width: 100%;
  grid-area: quickSearchBlock;
  width: 100%;
  margin: 0;
  padding-right: 10px;
}
.header .header__inner .search-utils form {
  max-width: 490px;
}
.header .header__inner .header-logo {
  grid-area: logo;
  margin: 0;
  max-width: 200px;
}
.header .header__inner .header-logo a {
  width: auto;
}
.header .header__inner .header-logo .header-logo-text {
  margin: 0;
}
.header .header__inner .header-logo img {
  max-height: initial;
  max-width: 200px;
}
.header .header__inner .navUser {
  grid-area: nav;
  height: 52px;
}
.header .header__inner .navUser .navUser-item--account {
  display: inline-flex;
  margin-right: 0;
}
.header .header__inner .navUser .navUser-item--account .navUser-text {
  display: none;
}
.header .header__inner .navUser .navUser-item--cart {
  margin-left: 8px;
  display: none;
}

.modal .productView {
  padding-top: 10px;
}

#form-action-addToCart {
  display: none;
}

.retail-group .header .header__inner .navUser .navUser-item--cart {
  display: initial;
}
.retail-group #qn-cart-to-quote {
  display: none;
}
.retail-group #form-action-addToCart {
  display: initial;
}

#qn-add-to-quote, .qn-add-to-quote {
  display: none;
}

.wholesale-group #qn-add-to-quote, .wholesale-group .qn-add-to-quote {
  display: initial;
}

.header--guest ~ .qn-button, .retail-group .qn-button {
  display: none;
}

@media screen and (max-width: 1023px) {
  .productCard--grid .card-section-rightWrapper, .productCard--list .card-section-rightWrapper {
    grid-column: 2;
  }
}
@media (max-width: 800px) {
  .header .header__inner .search {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .header .header__inner .navUser-item--account .dropdown-menu.is-open {
    display: none;
  }
}
@media (min-width: 1025px) {
  .header .header__inner {
    grid-template-columns: calc(50% - 175px) 1fr;
  }
  .header .header__inner .header-logo {
    max-width: 350px;
  }
  .header .header__inner .header-logo img {
    max-width: initial;
  }
}
@media (min-width: 801px) {
  .header .header__inner {
    grid-template-areas: "quickSearchBlock logo nav";
  }
  .header .header__inner .search {
    padding-right: 50px;
  }
  .header .header__inner .navUser {
    padding-top: 4px;
  }
  .header .header__inner .navUser .navUser-item--cart {
    margin-left: 16px;
  }
  #qn-add-to-quote, .qn-add-to-quote {
    margin-bottom: 0;
  }
}